export default {
  commKey: {
    xiazaijiandu: "Uploading",
    quanxuan: "All",
    keynameUser: "Search User",
    keynameDriver: "Search Driver",
    keynameCpy: "Search Company",
    keynameMap: "Search Plate or ID.",
    keyname: "Search",
    riqi: "Date",
    shouye: "Home",
    zhinengfalvzixun: "Home",
    falvfaguichaxun: "Home",
    anliku: "Home",
    xuanze: "Please Select",
    Search: "Search",
    Add: "Add",
    Edit: "Edit",
    Delete: "Del",
    Import: "Import​",
    Export: "Export",
    Template: "Template",
    Actions: "Request",
    Cancel: "Return",
    Confirm: "Save",
    BITIAN: "Required",
    CONFIRMTITLE: "Are you sure to delete this?",
    MSG_1: "Add OK.​",
    MSG_2: "Save OK.",
    MSG_3: "You are not authorized.",

    MSG_4: "Device IDs cannot be repeated.",
    MSG_5: "SIM card numbers cannot be repeated.",
    MSG_6: "Exceeding license.",
    MSG_7: "Cannot be repeated",
    MSG_TITLE: "Friendly Reminder",
    DELETESUCCESS: "Delete Done.",
    DELETEFAIL: "Delete Failed.",
    COMPANYS: "Company",
    VEHGROUP: "Fleet",
    IMPORTSUCCESS: "Import Done.",
    IMPORTFAIL: "Import Failed.",
    STARTDATE: "Start Time",
    ENDDATE: "End Time",
    DETAIL: "Details",
    MINGXI: "Details",
    EXCEL: "EXCEL",
    PDF: "PDF",
    QXZGS: "Please select company.​",
    QXZSCX: "Please select the data you want to delete.",
    QXZFZ: "Please select fleet.​",
    CARGROUPLEFT: "Not Authorized​",
    CARGROUPRIGHT: "Authorized​",
    QXZCZ: "Please select fleet.​",
    SAVE: "Save",
    SETTING: "SetUp",
    ZHUANGTAI: "State",
    CHEPAIHAO: "Plate No.",
    BINDCAR: "Authorized Vehicle​",
    UNBINDCAR: "Deauthorization",
    CAR_3: "Save Done.",
    CAR_33: "Plate No. numbers cannot be repeated.",
    CAR_3: "You are not authorized.",
    CAR_4: "Device IDs cannot be repeated.",
    CAR_5: "SIM card numbers cannot be repeated.",
    CAR_6: "Exceeding license.",
    CAR_7: "The fleet name and username have been occupied.​",
    CAR_8: "Username has been occupied.",
    CAR_9: "Fleet name has been occupied.",

    // Create: "Add",
    // DeleteSuccess: "Delete Done.",
    // DeleteFail: "Delete Failed.",
    // AddSuccess: "Add OK",
    // EditSuccess: "Edit Done.",
    // NoDataEdit: "No Data Changed.",
    // NoSame: "Occupied.",
    // NotifySuccessTitle: "Remind",
    // UpdateSuccess: "Update OK.",
    // DeleteRelation: "Delete Association.",
  },
  nav: {
    xtsz: "System",
    ssdw: "LIVE",
    gjhf: "Trip",
    sssp: "Video",
    lxhf: "Replay",
    zjzx: "RECORD",
    bb: "Report",
    bbcx: "Report",
    lcbb: "Trip",
    sxxtj: "Network",
    acckgbb: "Ignition",
    cgbj: "Alarm",
    gzbj: "Health",
    zdaq: "AI",
    dzwlcx: "Fence",
    sjll: "Cellular",
    yhrz: "Log",
    cljspf: "Vehicle Rating",
    sz: "System",
    clgl: "Vehicle",
    czgl: "Fleet",
    jsgl: "Role",
    gsgl: "Company",
    yhgl: "User",
    sjgl: "Driver",
    bxgl: "Insurance",
    simgl: "SIM Card",
    xtgl: "System",
    fwqgl: "Server",
    gzgl: "Rule",
    qygl: "Fence",
  },
};
