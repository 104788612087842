import GpsEventBroadcaster from "./GpsEventBroadcaster";
import store from "../store/index";
import md5 from "md5";
import VueCookies from "vue-cookies";
import api from "../api";

class GpsSocket {
  constructor(wsParaObj) {
    console.log("init", "GpsSocket");
    this.order_id = "";
    this.wsParaObj = wsParaObj;
    this.initSocket(); // Initialize socket.io server and socket.io options.
  }
  async initSocket() {
    const res = await api.get_web_socket_init({});
    // console.log(res);
    this.wsParaObj =
      "wss://" + res.data.SOCKET_IP + ":" + res.data.SOCKET_PORT + "/Order";
    // console.log("this.wsParaObj", this.wsParaObj);
    // if (VueCookies.get("gps_token")) {
    this.ws = new WebSocket(this.wsParaObj);
    this.ws.onopen = (e) => {
      console.log("Openened connection to websocket");
      //console.log(e);
    };
    this.ws.onclose = () => {
      console.log("Close connection to websocket");
      // 断线重连
      this.initSocket();
    };
    this.ws.onmessage = (e) => {
      // console.log("收到", e.data);
      var data = JSON.parse(e.data);
      GpsEventBroadcaster.broadcast(data);
      if (data.type == "answer") {
        this.order_id = data.id;
      }

      // console.log('id',data.id)
    };
    // }
  }
  addListener(fun) {
    GpsEventBroadcaster.addListener(fun);
  }
  makeSign(obj) {
    let md5_key = "J04Tb9pcLF7y6QrFmgTJLeVv33YbMhqW";
    let ay_url = [];
    Object.keys(obj)
      .sort()
      .forEach(function (key) {
        ay_url.push(
          key.toLowerCase() + "=" + obj[key]?.toString().toLowerCase()
        );
      });
    ay_url.push("key=" + md5_key.toLowerCase());

    let url = ay_url.join("&");
    // console.log(url);
    return md5(url).toUpperCase();
  }
  sendMsgToDB(order_type, jsonObj, fn) {
    let obj = {
      username: VueCookies.get("username"),
      userpwd: VueCookies.get("pwd"),
      time: Math.floor(new Date().getTime() / 1000),
    };

    // jsonObj['username'] = obj.username;
    // jsonObj['time'] = obj.time;
    // jsonObj['sign'] = this.makeSign(obj);

    let that = this;
    this.waitForConnection(() => {
      let msg_temp = {
        id: this.order_id,
        type: order_type,
        content: jsonObj,
        username: obj.username,
        time: obj.time,
        sign: this.makeSign(obj),
      };
      // console.log('sendMsgToDB',JSON.stringify(msg_temp));
      (typeof fn === "function") & fn(msg_temp);
      this.ws.send(JSON.stringify(msg_temp));
    }, 1000);
  }
  sendMsg(order_type, jsonObj) {
    let obj = {
      username: store.getters.uName,
      userpwd: store.getters.pwd,
      time: Math.floor(new Date().getTime() / 1000),
    };

    let msg_temp = {
      id: this.order_id,
      type: order_type,
      content: jsonObj,
      username: obj.username,
      time: obj.time,
      sign: this.makeSign(obj),
    };

    console.log("发送zhy", msg_temp);

    this.ws.send(JSON.stringify(msg_temp));

    // console.log('send', JSON.stringify({
    //   content: msg, type: type, id: this.id, encrypt: 0, serialno: this.serialno
    // }))
    // if (serialno == "") {
    //   serialno = this.guid();
    // }

    // this.ws.send(
    //   JSON.stringify({
    //     content: msg,
    //     type: type,
    //     id: this.id,
    //     encrypt: 0,
    //     serialno: serialno,
    //   })
    // );
  }
  waitForConnection(callback, interval) {
    // if(this.websocket.readyState === 1){
    if (this.isSend == 1) {
      callback();
    } else {
      //
      let that = this;
      setTimeout(function () {
        that.waitForConnection(callback, interval);
      }, interval);
    }
  }
  //用于生成uuid
  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  guid() {
    return (
      this.S4() +
      this.S4() +
      "-" +
      this.S4() +
      "-" +
      this.S4() +
      "-" +
      this.S4() +
      "-" +
      this.S4() +
      this.S4() +
      this.S4()
    );
  }
}

let socket = new GpsSocket("wss://test.cnms365.com:9006/Order"); //150.158.83.233  127.0.0.1

function order_send(ay_dev, msg) {
  for (var i = 0; i < ay_dev.length; i++) {
    mysocket.sendMsg("order", {
      deviceid: ay_dev[i],
      order: "8300",
      content: {
        msgtype: "00001101",
        msg: msg,
      },
    });
  }
}

/**
 *  81802 里程补偿
 * @param ay_dev
 * @param value
 */
function lcbc81802(ay_dev, value) {
  mysocket.sendMsg("order", {
    deviceid: ay_dev,
    order: "8300",
    content: {
      msg: "#SET MILEAGE:" + value,
    },
  });
}

/**
 *  81802 格式化磁盘
 * @param ay_dev
 * @param type
 */
function gshcp81802(ay_dev, type) {
  mysocket.sendMsg("order", {
    deviceid: ay_dev,
    order: "8300",
    content: {
      msgtype: "00001000",
      msg: "#SETDIS_FORM:" + type,
    },
  });
}

function refresh_table(url, tables, min) {
  // console.log('wxw','refresh_table');
  var json = {
    id: "11111-11111-11111-11111",
    type: "refresh",
    content: { tablename: tables, refreshtype: 5 },
  };

  $.post(
    url,
    {
      id: 0,
      oDeviceID: 0,
      oDevType: 0,
      oOrderType: "refresh",
      oOrderContent: JSON.stringify(json),
      oSendState: 0,
      userid: userid,
      token: token,
    },
    function (data) {}
  );
  mysocket.sendMsgToDB(
    "refresh",
    {
      tablename: tables,
      refreshtype: min,
    },
    function (json) {}
  );
}

function search_sbcs_by_deviceid(url, deviceid, dev_type) {
  mysocket.sendMsgToDB(
    "order",
    {
      deviceid: deviceid,
      order: "8104",
      content: "",
    },
    function (json) {
      $.post(
        url,
        {
          id: 0,
          oDeviceID: deviceid,
          oDevType: dev_type,
          oOrderType: "8104",
          oOrderContent: JSON.stringify(json),
          oSendState: 0,
          userid: userid,
          token: token,
        },
        function (data) {}
      );
    }
  );
}

function sbcs_send(
  url,
  deviceid,
  dev_type,
  var_1,
  var_2,
  var_3,
  var_4,
  var_5,
  var_6,
  var_7,
  var_8,
  var_9,
  var_10
) {
  mysocket.sendMsgToDB(
    "order",
    {
      deviceid: deviceid,
      order: "8103",
      content: {
        varcount: 10,
        varlist: [
          {
            varid: "00000081",
            " vartype ": 2,
            varvalue: var_1,
          },
          {
            varid: "00000082",
            vartype: 2,
            varvalue: var_2,
          },
          {
            varid: "00000083",
            vartype: 10,
            varvalue: var_3,
          },
          {
            varid: "00000084",
            vartype: 1,
            varvalue: var_4,
          },
          {
            varid: "00000029",
            vartype: 4,
            varvalue: var_5,
          },
          {
            varid: "FFFF0002",
            vartype: 1,
            varvalue: var_6,
          },
          {
            varid: "FFFF0004",
            vartype: 1,
            varvalue: var_7,
          },
          {
            varid: "FFFF0017",
            vartype: 1,
            varvalue: var_8,
          },
          {
            varid: "FFFF0019",
            vartype: 1,
            varvalue: var_9,
          },
          {
            varid: "FFFF001A",
            vartype: 1,
            varvalue: var_10,
          },
        ],
      },
    },
    function (json) {
      $.post(
        url,
        {
          id: 0,
          oDeviceID: deviceid,
          oDevType: dev_type,
          oOrderType: "8103",
          oOrderContent: JSON.stringify(json),
          oSendState: 0,
          userid: userid,
          token: token,
        },
        function (data) {}
      );
    }
  );
}

function fwqsz_send(
  url,
  deviceid,
  dev_type,
  var_1,
  var_2,
  var_3,
  var_4,
  var_5
) {
  mysocket.sendMsgToDB(
    "order",
    {
      deviceid: deviceid,
      order: "8103",
      content: {
        varcount: 10,
        varlist: [
          {
            varid: "00000010",
            " vartype ": 10,
            varvalue: var_1,
          },
          {
            varid: "00000013",
            vartype: 10,
            varvalue: var_2,
          },
          {
            varid: "00000017",
            vartype: 10,
            varvalue: var_3,
          },
          {
            varid: "00000018",
            vartype: 4,
            varvalue: var_4,
          },
          {
            varid: "00000019",
            vartype: 4,
            varvalue: var_5,
          },
        ],
      },
    },
    function (json) {
      var str_json = JSON.stringify(json);
      // str_json = str_json.replace(/\"/g,"\\\"");
      $.post(
        url,
        {
          id: 0,
          oDeviceID: deviceid,
          oDevType: dev_type,
          oOrderType: "8103",
          oOrderContent: str_json,
          oSendState: 0,
          userid: userid,
          token: token,
        },
        function (data) {}
      );
    }
  );
}

////////////////////////////////////////////////////
function open_video(
  sn,
  channel_id,
  select_win,
  sn_old,
  channel_id_old,
  fn,
  cph
) {
  if (typeof sn !== "undefined" && sn != "" && channel_id > 0) {
    var video_ip_array = "{$Think.config.Video_Server_Ip}";
    video_ip_array = video_ip_array.split("#");
    var video_port = "{$Think.config.Video_Server_Port}";
    mysocket.sendMsg("order", {
      deviceid: sn,
      order: "9101",
      content:
        video_ip_array[0] + "," + video_port + ",0," + channel_id + ",0,1",
    });
    typeof fn === "function" && fn(sn, channel_id, select_win, cph);
  }
}

function open_video_mute(sn, channel_id, flag) {
  if (typeof sn !== "undefined" && sn != "" && channel_id > 0) {
    var video_ip_array = "{$Think.config.Video_Server_Ip}";
    video_ip_array = video_ip_array.split("#");
    var video_port = "{$Think.config.Video_Server_Port}";
    console.log("open_video_mute1");
    mysocket.sendMsg("order", {
      deviceid: sn,
      order: "9101",
      content:
        video_ip_array[0] +
        "," +
        video_port +
        ",0," +
        channel_id +
        "," +
        flag +
        ",1",
    });
    console.log("open_video_mute2");
  }
}

function back_video(sn, channel_id, select_win, sn_old, channel_id_old, fn) {
  // console.log(sn,channel_id,st);
  if (typeof sn_old !== "undefined" && sn_old != "" && channel_id_old > 0) {
    mysocket.sendMsg("order", {
      deviceid: sn_old,
      order: "9202",
      content: channel_id_old + ",0,0",
    });
  }

  if (typeof sn !== "undefined" && sn != "" && channel_id > 0) {
    var video_ip_array = "{$Think.config.Video_Server_Ip}";
    video_ip_array = video_ip_array.split("#");
    var video_port = "{$Think.config.Video_Server_Port_Playback}";
    mysocket.sendMsg("order", {
      deviceid: sn,
      order: "9201",
      content:
        video_ip_array[0] + "," + video_port + ",0," + channel_id + ",0,1",
    });
    typeof fn === "function" && fn(sn, channel_id, select_win);
  }
}

function search_one(sn, st, et, obj, fn) {
  /*"018124567840"*/
  mysocket.sendMsgAndBack(
    "order",
    {
      deviceid: sn,
      order: "9205",
      content: "0," + st + "," + et + ",0,0,1,1",
    },
    function (data_json) {
      typeof fn === "function" && fn(data_json);
      console.log("json", data_json);
    }
  );
}

function search_two(sn, st, et, channel, select_win, fn) {
  var video_ip_array = "{$Think.config.Video_Server_Ip}";
  video_ip_array = video_ip_array.split("#");
  var video_port = "{$Think.config.Video_Server_Port_Playback}";
  mysocket.sendMsgAndBack(
    "order",
    {
      deviceid: sn /*"018124567840"*/,
      order: "9201",
      content:
        video_ip_array[0] +
        "," +
        video_port +
        ",0," +
        channel +
        ",0,1,1,0,0," +
        st +
        "," +
        et +
        "",
    },
    function (data_json) {
      typeof fn === "function" && fn(sn, channel, select_win, data_json);
    }
  );
  // 	setTimeout(function () {
  // 		//发送播放视频
  // //		 mysocket.sendMsg("order",{"deviceid":sn ,"order": "9202","content": ""+channel+",5,0,"+st+""});/*"018124567840"*/
  // 		typeof fn === 'function' && fn(sn,channel,select_win);
  // 	}, 1000)
}

function back_socket(sn, order, node, fn) {
  mysocket.sendMsgAndBack(
    "order",
    { deviceid: sn, order: order, content: node },
    function (data_json) {
      typeof fn === "function" && fn(data_json);
    }
  );
}

function get_socket(obj, fn) {
  mysocket.sendMsgAndBack("order", obj, function (data_json) {
    typeof fn === "function" && fn(data_json);
  });
}

export default socket;
