<template>
  <div class="root">
    <div class="container-shell">
      <div class="container-shell-title">
        <span class="tag-version" v-if="version">({{ version }})</span>
      </div>
      <div class="option">
        <span> {{ $t("map.huanchongmiao") }}:</span>
        <input
          style="width: 50px"
          type="number"
          ref="buffer"
          value="1"
          @change="changeBuffer"
        />
        <input
          type="checkbox"
          v-model="useMSE"
          ref="vod"
          @change="restartPlay('mse')"
        /><span>MediaSource</span>
        <input
          type="checkbox"
          v-model="useWCS"
          ref="vod"
          @change="restartPlay('wcs')"
        /><span>webcodecs</span>
      </div>
      <div
        class="container"
        id="container"
        ref="container"
        :style="{ width: width + 'px', height: height + 'px' }"
      ></div>
      <div class="input hide">
        <div>{{ $t("map.shuru") }}URL：</div>
        <input type="input" autocomplete="on" ref="playUrl" value="" />
        <button v-if="!playing" @click="play">{{ $t("map.track_bf") }}</button>
        <button v-else @click="pause">{{ $t("map.tingzhi") }}</button>
      </div>
      <div class="input hide" v-if="loaded" style="line-height: 30px">
        <button @click="destroy">{{ $t("map.xiaohui") }}</button>
        <button v-if="quieting" @click="cancelMute">
          {{ $t("map.quxiaojingyin") }}
        </button>
        <template v-else>
          <button @click="mute">{{ $t("map.jingyin") }}</button>
          {{ $t("map.yinliang") }}
          <select v-model="volume" @change="volumeChange">
            <option value="1">100</option>
            <option value="0.75">75</option>
            <option value="0.5">50</option>
            <option value="0.25">25</option>
          </select>
        </template>
        <span>{{ $t("map.xuanzhuang") }}</span>
        <select v-model="rotate" @change="rotateChange">
          <option value="0">0</option>
          <option value="90">90</option>
          <option value="270">270</option>
        </select>

        <button @click="fullscreen">{{ $t("map.quanping") }}</button>
        <button @click="screenShot">{{ $t("map.jietu") }}</button>
        <div style="line-height: 30px">
          <input
            type="checkbox"
            ref="operateBtns"
            v-model="showOperateBtns"
            @change="restartPlay"
          /><span>{{ $t("map.caozuoanniu") }}</span>
          <input
            type="checkbox"
            ref="operateBtns"
            v-model="showBandwidth"
            @change="restartPlay"
          /><span>{{ $t("map.wangsu") }}</span>
          <span v-if="performance"
            >{{ $t("map.xingneng") }}：{{ performance }}</span
          >
        </div>
      </div>
      <div class="input hide" v-if="loaded">
        <input
          type="checkbox"
          ref="offscreen"
          v-model="useOffscreen"
          @change="restartPlay('offscreen')"
        /><span>{{ $t("map.lipingxuanran") }}</span>

        <select v-model="scale" @change="scaleChange">
          <option value="0">{{ $t("map.wanquantianchonglashen") }}</option>
          <option value="1">{{ $t("map.dengbisuofang") }}</option>
          <option value="2">{{ $t("map.wanquantianchongweilashen") }}</option>
        </select>
        <button v-if="!playing" @click="clearView">
          {{ $t("map.qingping") }}
        </button>
        <template v-if="playing">
          <select v-model="recordType">
            <option value="webm">webm</option>
            <option value="mp4">mp4</option>
          </select>
          <button v-if="!recording" @click="startRecord">
            {{ $t("map.luzhi") }}
          </button>
          <button v-if="!recording" @click="stopAndSaveRecord">
            {{ $t("map.zantingluzhi") }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { markRaw } from "vue";

export default {
  name: "GpsVideo",
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    channelidx: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cphText: "init",
      row: {},
      jessibuca: null,
      version: "",
      wasm: false,
      vc: "ff",
      playing: false,
      quieting: true,
      loaded: false, // mute
      showOperateBtns: false,
      showBandwidth: false,
      err: "",
      speed: 0,
      performance: "",
      volume: 1,
      rotate: 0,
      useWCS: false,
      useMSE: true,
      useOffscreen: false,
      recording: false,
      recordType: "webm",
      scale: 0,
    };
  },
  mounted() {
    this.create();
    window.onerror = (msg) => (this.err = msg);
  },
  unmounted() {
    this.jessibuca.destroy();
  },
  methods: {
    create(options) {
      options = options || {};
      let that = this;
      this.jessibuca = markRaw(
        new window.Jessibuca(
          Object.assign(
            {
              channelidx: this.channelidx,
              container: this.$refs.container,
              // videoBuffer: Number(this.$refs.buffer.value), // 缓存时长
              isResize: false,
              // useWCS: this.useWCS,
              // useMSE: this.useMSE,
              text: "111111111111111",
              // background: "bg.jpg",
              loadingText: "LOADING...",
              // hasAudio:false,
              debug: false,
              supportDblclickFullscreen: true,
              // showBandwidth: this.showBandwidth, // 显示网速
              operateBtns: {
                fullscreen: true,
                screenshot: true,
                play: true,
                audio: true,
              },
              // operateBtns: {
              //   fullscreen: this.showOperateBtns,
              //   screenshot: this.showOperateBtns,
              //   play: this.showOperateBtns,
              //   audio: this.showOperateBtns,
              // },
              vod: this.vod,
              forceNoOffscreen: !this.useOffscreen,
              isNotMute: false,
              timeout: 10,
            },
            options
          )
        )
      );
      var _this = this;
      console.log("tanggong", "create", this.channelidx);
      this.jessibuca.on("load", function () {
        // console.log("on load");
      });

      this.jessibuca.on("log", function (msg) {
        // console.log("on log", msg);
      });
      this.jessibuca.on("record", function (msg) {
        // console.log("on record:", msg);
      });
      this.jessibuca.on("pause", function () {
        // console.log("on pause");
        _this.playing = false;
      });
      this.jessibuca.on("play", function () {
        // console.log("on play");
        _this.playing = true;
        // this.cphText = "测试车牌";
      });
      this.jessibuca.on("fullscreen", function (msg) {
        // console.log("on fullscreen", msg);
      });

      this.jessibuca.on("mute", function (msg) {
        // console.log("on mute", msg);
        _this.quieting = msg;
      });

      this.jessibuca.on("mute", function (msg) {
        // console.log("on mute2", msg);
      });

      this.jessibuca.on("audioInfo", function (msg) {
        // console.log("audioInfo", msg);
      });

      // this.jessibuca.on("bps", function (bps) {
      //   // console.log('bps', bps);
      // });
      // let _ts = 0;
      // this.jessibuca.on("timeUpdate", function (ts) {
      //     console.log('timeUpdate,old,new,timestamp', _ts, ts, ts - _ts);
      //     _ts = ts;
      // });

      this.jessibuca.on("videoInfo", function (info) {
        // console.log("videoInfo", info);
      });

      this.jessibuca.on("error", function (error) {
        console.log("error", error);
      });

      this.jessibuca.on("timeout", function () {
        console.log("timeout");
      });

      this.jessibuca.on("start", function () {
        // console.log("frame start");
      });

      this.jessibuca.on("performance", function (performance) {
        var show = that.$t("map.kadun");
        if (performance === 2) {
          show = that.$t("map.feichangliuchang");
        } else if (performance === 1) {
          show = that.$t("map.liuchang");
        }
        _this.performance = show;
      });
      this.jessibuca.on("buffer", function (buffer) {
        console.log("buffer", buffer);
      });

      this.jessibuca.on("stats", function (stats) {
        console.log("stats", stats);
      });

      this.jessibuca.on("kBps", function (kBps) {
        console.log("kBps", kBps);
      });

      this.jessibuca.on("play", () => {
        this.playing = true;
        this.loaded = true;
        this.quieting = this.jessibuca.isMute();
      });

      this.jessibuca.on("recordingTimestamp", (ts) => {
        console.log("recordingTimestamp", ts);
      });

      // console.log(this.jessibuca);
    },
    setVidoName(idx, name, ch) {
      // console.log(name, ch, this.jessibuca.updatecph);

      this.jessibuca.updatecph(idx, name + " - " + ch);
    },
    playUrl(url) {
      console.log("wxw-play", url);
      if (url != "") {
        //this.$refs.playUrl.value = url;
        this.jessibuca.play(url);
        // this.jessibuca.setCph("wxw");
        // console.log("wxw-wx", playUrl);
      }
    },
    play() {
      // this.jessibuca.onPlay = () => (this.playing = true);

      if (this.$refs.playUrl.value) {
        this.jessibuca.play(this.$refs.playUrl.value);
      }
    },
    mute() {
      this.jessibuca.mute();
    },
    cancelMute() {
      this.jessibuca.cancelMute();
    },

    pause() {
      this.jessibuca.pause();
      this.playing = false;
      this.err = "";
      this.performance = "";
    },
    volumeChange() {
      this.jessibuca.setVolume(this.volume);
    },
    rotateChange() {
      this.jessibuca.setRotate(this.rotate);
    },
    destroyOnly() {
      if (this.jessibuca) {
        this.jessibuca.destroy();
        console.log("tanggong", "destroyOnly", this.channelidx);
      }
    },
    destroy() {
      console.log("wxw video destroy");
      if (this.jessibuca) {
        this.jessibuca.destroy();
        console.log("wxw tanggong", "destroy", this.channelidx);
      }

      this.playing = false;
      this.loaded = false;
      this.performance = "";
      setTimeout(() => {
        this.create();
        console.log("wxw video create");
      }, 1);
    },

    fullscreen() {
      this.jessibuca.setFullscreen(true);
    },

    clearView() {
      this.jessibuca.clearView();
    },
    resize() {
      this.jessibuca.resize();
    },
    startRecord() {
      const time = new Date().getTime();
      this.jessibuca.startRecord(time, this.recordType);
    },

    stopAndSaveRecord() {
      console.log("stopAndSaveRecord");
      this.jessibuca.stopRecordAndSave();
    },

    screenShot() {
      this.jessibuca.screenshot();
    },

    restartPlay(type) {
      if (type === "mse") {
        this.useWCS = false;
        this.useOffscreen = false;
      } else if (type === "wcs") {
        this.useMSE = false;
      } else if (type === "offscreen") {
        this.useMSE = false;
      }

      this.destroy();
      setTimeout(() => {
        this.play();
      }, 100);
    },

    changeBuffer() {
      this.jessibuca.setBufferTime(Number(this.$refs.buffer.value));
    },

    scaleChange() {
      this.jessibuca.setScaleMode(this.scale);
    },
  },
};
</script>
<style scope lang="scss">
.root {
  display: flex;
  background: url(../assets/2.png);
  background-size: cover;
  width: inherit;
  height: inherit;
  /* place-content: center;
  margin-top: 3rem; */
}

.container-shell {
  box-sizing: border-box;
  position: relative;
  // backdrop-filter: blur(5px);
  // background: hsla(0, 0%, 50%, 0.5);
  /* padding: 30px 4px 10px 4px; */
  /* border: 2px solid black; */
  width: auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 1px 5px;
}

.container-shell-title {
  position: absolute;
  color: darkgray;
  top: 4px;
  left: 10px;
  text-shadow: 1px 1px black;
  display: none;
}

.tag-version {
}

#container {
  box-sizing: border-box;
  // background: rgba(13, 14, 27, 0.7);
  width: 320px;
  height: 200px;
}

.input {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: white;
  place-content: stretch;
}

.input2 {
  bottom: 0px;
}

.input input[type="input"] {
  flex: auto;
}

.err {
  position: absolute;
  top: 40px;
  left: 10px;
  color: red;
}

.hide {
  display: none;
}

.option {
  position: absolute;
  top: 4px;
  right: 10px;
  display: flex;
  place-content: center;
  font-size: 12px;
  display: none;
}

.option span {
  color: white;
}

.page {
  background: url(/bg.jpg);
  background-repeat: no-repeat;
  background-position: top;
}

// @media (max-width: 720px) {
//   #container {
//     width: 90vw;
//     height: 52.7vw;
//   }
// }
</style>
