<template>
  <el-container class="gps-container">
    <el-header>
      <div class="gps-header d-flex flex-row">
        <div class="logo"><img :src="logo" alt="" /></div>
        <HeaderMenu></HeaderMenu>
        <div
          class="quit d-flex flex-row justify-content-end"
          style="padding-right: 20px"
        >
          <div
            v-for="it in mainNav"
            :key="'mainNav' + it.id"
            :class="['menu_items', act == it.mRName ? 'act' : '']"
            @click="go_url({ name: it.mRName })"
          >
            <div v-if="it.mTitle == '系统设置' || it.mTitle == 'SYSTEM'">
              <img
                :src="'./meun/' + it.mIcon"
                :title="it.mTitle"
                style="
                  vertical-align: middle;
                  margin-right: 2px;
                  width: 32px;
                  height: 32px;
                "
              />
              <!-- {{ it.mTitle }} -->
            </div>
          </div>
          <div class="pr10">
            <span
              :title="$t('map.guzhang')"
              @click="clickAlarm"
              class="iconfont gps_control_font"
              >&#xe65c;</span
            >
          </div>
          <div>
            <span
              @click="clickDownload"
              :title="$t('map.xiazai')"
              class="iconfont gps_control_font"
              >&#xe600;</span
            >
          </div>
          <div class="ai-alarm" @click="btnAlarm">
            <div class="ai-alarm-1">
              <div class="alarm-num">
                {{ alarm_num
                }}<!--{{ alarmData.length }}-->
              </div>
            </div>
          </div>
          <!-- <div
            style="
              display: inline-flex;
              margin-right: -20px;
              padding-top: 5px;
              cursor: pointer;
            "
          >
            <img src="../../assets/alarm.gif" alt="" @click="btnAlarm" />
          </div> -->
          <el-dropdown>
            <span class="el-dropdown-link link-title">
              {{ $store.getters.uName
              }}<el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown
              ><el-dropdown-menu>
                <el-dropdown-item @click="editpwd">
                  {{ $t("map.xiugaimima") }}
                </el-dropdown-item>
                <el-dropdown-item
                  >{{ $t("map.banbenhao") }} V1.3</el-dropdown-item
                >
                <el-dropdown-item @click="logout">{{
                  $t("map.tuichu")
                }}</el-dropdown-item>
              </el-dropdown-menu></template
            >
          </el-dropdown>
        </div>
      </div>
    </el-header>
    <el-main>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
      <!-- <keep-alive><router-view /></keep-alive> -->
    </el-main>
    <el-drawer
      v-model="alarmDrawer"
      direction="rtl"
      size="90%"
      :destroy-on-close="true"
      :show-close="true"
    >
      <div class="alarm">
        <!-- <div class="alarm-top">
          <div
            :class="{ 'top-it': true, time_act: time_act == 1 }"
            @click="timeClick(1)"
          >
            最近1分钟
          </div>
          <div
            :class="{ 'top-it': true, time_act: time_act == 2 }"
            @click="timeClick(2)"
          >
            最近5分钟
          </div>
          <div
            :class="{ 'top-it': true, time_act: time_act == 3 }"
            @click="timeClick(3)"
          >
            全部未处理
          </div>
        </div> -->
        <div class="alarm-bottom">
          <div class="alarm-l">
            <el-tabs tab-position="left" style="height: calc(100vh - 100px)">
              <el-tab-pane
                :label="tab.label + '(' + tab.num + ')'"
                v-for="tab in leftNav"
                :key="'t' + tab.label"
              >
                <div
                  @click="search_data(it)"
                  :class="{
                    'alarm-it': true,
                    'alarm-act': sel_val == it.value,
                  }"
                  v-for="it in tab.children"
                  :key="it.label"
                >
                  {{ it.label + "(" + it.num + ")" }}
                </div>
              </el-tab-pane>
              <!-- <el-tab-pane label="报警事件">Config</el-tab-pane>
              <el-tab-pane label="警告注意">Role</el-tab-pane>
              <el-tab-pane label="事件通知">Task</el-tab-pane> -->
            </el-tabs>
          </div>
          <div class="alarm-r" style="height: calc(100vh - 100px)">
            <el-table
              :data="dataAlram"
              border
              stripe
              @row-dblclick="onRowDblclick"
            >
              <el-table-column
                align="center"
                v-for="col in columns"
                :key="col.field"
                :label="col.label"
                :width="col.width"
                :show-overflow-tooltip="true"
              >
                <template #default="{ row }">
                  <span v-if="col.type == 'img'"
                    ><img
                      :src="row[col.field]"
                      style="width: 30px; heigth: 30px"
                  /></span>
                  <span v-else-if="col.type == 'zt'">
                    <b v-if="row[col.field] == 1" style="color: #909399">{{
                      row[col.field + "_name"]
                    }}</b>
                    <b v-if="row[col.field] == 2" style="color: #67c23a">{{
                      row[col.field + "_name"]
                    }}</b>
                    <b v-if="row[col.field] == 3" style="color: #f56c6c">{{
                      row[col.field + "_name"]
                    }}</b>
                  </span>
                  <span v-else-if="col.type == 'fun'" v-html="col.render(row)">
                  </span>
                  <span v-else-if="typeof col.type === 'undefined'">{{
                    row[col.field]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="left"
                :label="$t('map.caozuo')"
                width="100"
              >
                <template #default="{ row }">
                  <el-button @click="view_click(row)" class="bbr">
                    <span class="iconfont icon-yanjing_xianshi da3"></span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-drawer>
    <GpsAlarm :isShow="show_alarm" @ErpDialogEvent="eventAlarm"></GpsAlarm>
    <GpsDownload
      :isShow="show_download"
      @ErpDialogEvent="eventDownload"
    ></GpsDownload>
    <GpsAlarmDetail
      :isShow="show_alarm_detail"
      @ErpDialogEvent="eventAlarmDetail"
      :rowAlarm="rowAlarm"
    ></GpsAlarmDetail>
    <el-dialog
      :title="$t('map.xiugaimima')"
      v-model="editClickDialog"
      width="500px"
      :append-to-body="true"
      :close-on-click-modal="false"
      @opened="editOpened"
      :destroy-on-close="true"
    >
      <el-form :model="edit_form" id="mima">
        <el-form-item :label="$t('login.oPwd')">
          <el-input type="password" v-model="edit_form.oPwd" />
        </el-form-item>
        <el-form-item :label="$t('login.uPwd')">
          <el-input type="password" v-model="edit_form.uPwd" />
        </el-form-item>
        <el-form-item :label="$t('login.cPwd')">
          <el-input type="password" v-model="edit_form.cPwd" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("login.btnSave")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- <TestView :isShow="true"></TestView> -->
  </el-container>
</template>

<script>
// import SideMenu from "./aside/SideMenu";
import { useRouter } from "vue-router";
import { ref, getCurrentInstance } from "vue";
import HeaderMenu from "./header/HeaderMenu.vue";
import { ArrowDown } from "@element-plus/icons-vue";
import GpsDownload from "../../components/GpsDownload.vue";
import GpsAlarm from "../../components/GpsAlarm.vue";
import GpsAlarmDetail from "../../components/GpsAlarmDetail.vue";
import GpsSocket from "../../utils/GpsSocket";
// import TestView from "../../components/TestView.vue";
export default {
  name: "layout",
  components: {
    HeaderMenu,
    ArrowDown,
    GpsDownload,
    GpsAlarm,
    GpsAlarmDetail,
    // TestView,
  },
  computed: {
    getNum() {
      let num = 0;
      this.leftNav.forEach((it) => {
        num += parseInt(it.num);
      });
      return num;
    },
  },
  setup() {
    let { proxy } = getCurrentInstance();
    const router = useRouter();
    const act = ref("index");
    const go_url = (url) => {
      router.push(url);
      act.value = url.name;
    };
    const mainNav = ref([]);
    proxy.$api.menu_get_menu_list_by_role({ pid: 0 }).then((res) => {
      // console.log(res.data);
      mainNav.value = res.data.list;
    });
    return { go_url, act, mainNav };
  },
  data() {
    return {
      alarm_num: 0,
      logo: 'require("../../assets/menu-login.jpg")',
      edit_form: { oPwd: "", uPwd: "", cPwd: "" },
      editClickDialog: false,
      ay_alarm: [
        1, 30, 207, 2, 20, 26, 216, 9, 29, 102, 201, 205, 213, 214, 215, 301,
        304, 352, 353, 354, 431, 432, 433, 434,
      ], //221, 222, 223, 224,
      dataList: {},
      alarmData: [],
      rowAlarm: {},
      show_alarm_detail: false,
      time_act: 3,
      sel_val: "101",
      show_download: false,
      show_alarm: false,
      maxheight: window.innerHeight - 110,
      leftNav: [
        {
          label: this.$t("map.zhimingjingbao"),
          value: "",
          num: 0,
          children: [
            {
              label: this.$t("map.a_1"),
              num: 0,
              value: "1",
            },
            {
              label: this.$t("map.pengzhuang"),
              num: 0,
              value: "30",
            },
            // {
            //   label: "侧翻预警",
            //   value: "31",
            // },
            {
              label: this.$t("map.pilaobaojing"),
              num: 0,
              value: "207",
            },
          ],
        },
        {
          label: this.$t("map.baojingshijian"),
          value: "",
          num: 0,
          children: [
            {
              label: this.$t("map.a_2"),
              num: 0,
              value: "2",
            },
            {
              label: this.$t("map.e_20"),
              value: "20",
              num: 0,
            },
            {
              label: this.$t("map.e_26"),
              value: "26",
              num: 0,
            },
            // {
            //   label: "疲劳驾驶报警",
            //   value: "207",
            // },
            {
              label: this.$t("map.e_216"),
              value: "216",
              num: 0,
            },
            // {
            //   label: "检测安全带",
            //   value: "",
            // },
          ],
        },
        {
          label: this.$t("map.jinggaozhuyi"),
          value: "",
          num: 0,
          children: [
            // {
            //   label: "终端主电源欠压",
            //   value: "8",
            //   num: 0,
            // },
            {
              label: this.$t("map.yichangduandian"), //"终端主电源掉电",
              value: "9",
              num: 0,
            },
            {
              label: this.$t("map.tuoche"), //"车辆非法位移",
              value: "29",
              num: 0,
            },
            {
              label: this.$t("map.shipinzhedang"), //"视频信号遮挡报警",
              value: "102",
              num: 0,
            },
            // {
            //   label: "异常驾驶行为报警",
            //   value: "106",
            //   num: 0,
            // },
            {
              label: this.$t("map.e_65"),
              value: "201",
              num: 0,
            },
            // {
            //   label: "区域低速报警",
            //   value: "202",
            //   num: 0,
            // },
            // {
            //   label: "进出区域报警",
            //   value: "203",
            // },
            {
              label: this.$t("map.e_205"),
              value: "205",
              num: 0,
            },
            // {
            //   label: "时间段低速报警",
            //   value: "206",
            //   num: 0,
            // },
            {
              label: this.$t("map.e_213"),
              value: "213",
              num: 0,
            },
            {
              label: this.$t("map.e_214"),
              value: "214",
              num: 0,
            },
            {
              label: this.$t("map.e_215"),
              value: "215",
              num: 0,
            },
            {
              label: this.$t("map.e_301"),
              value: "301",
              num: 0,
            },
            {
              label: this.$t("map.e_304"),
              value: "304",
              num: 0,
            },
            {
              label: this.$t("map.e_352"),
              value: "352",
              num: 0,
            },
            {
              label: this.$t("map.e_353"),
              value: "353",
              num: 0,
            },
            {
              label: this.$t("map.e_3541"),
              value: "354",
              num: 0,
            },
            {
              label: this.$t("map.e_431"),
              value: "431",
              num: 0,
            },
            {
              label: this.$t("map.e_432"),
              value: "432",
              num: 0,
            },
            {
              label: this.$t("map.e_433"),
              value: "433",
              num: 0,
            },
            {
              label: this.$t("map.e_434"),
              value: "434",
              num: 0,
            },
          ],
        },
        // {
        //   label: this.$t("map.shijiantongzhi"),
        //   value: "",
        //   num: 0,
        //   children: [
        //     // {
        //     //   label: "IO1报警",
        //     //   value: "217",
        //     // },
        //     // {
        //     //   label: "前门报警",
        //     //   value: "218",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "中门报警",
        //     //   value: "219",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "后门报警",
        //     //   value: "220",
        //     //   num: 0,
        //     // },
        //     {
        //       label: this.$t("map.yz"),
        //       value: "221",
        //       num: 0,
        //     },
        //     {
        //       label: this.$t("map.zz"),
        //       value: "222",
        //       num: 0,
        //     },
        //     {
        //       label: this.$t("map.cc"),
        //       value: "223",
        //       num: 0,
        //     },
        //     {
        //       label: this.$t("map.dc"),
        //       value: "224",
        //       num: 0,
        //     },

        //     // {
        //     //   label: "胎压（定时上报）",
        //     //   value: "380",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "胎压过高报警",
        //     //   value: "381",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "胎压过低报警",
        //     //   value: "382",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "胎温过高报警",
        //     //   value: "383",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "传感器异常报警",
        //     //   value: "384",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "胎压不平衡报警",
        //     //   value: "385",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "慢漏气报警",
        //     //   value: "386",
        //     //   num: 0,
        //     // },
        //     // {
        //     //   label: "电池电量低报警",
        //     //   value: "387",
        //     //   num: 0,
        //     // },
        //   ],
        // },
      ],
      dataAlram: [],
      columns: [
        { field: "cph", label: this.$t("map.cph_name"), width: "150px" },
        { field: "cpy_name", label: this.$t("map.ssgs_name"), width: "150px" },
        {
          field: "gps_time",
          label: this.$t("map.zxbjsj_name"),
          width: "150px",
        },
        // {
        //   field: "alarmlevel",
        //   label: this.$t("map.bjjb"),
        //   width: "100px",
        //   type: "fun",
        //   render: (row) => {
        //     let pos = "";
        //     if (row["alarmlevel"] == 0) {
        //       pos = "";
        //     } else {
        //       pos = "<span>" + obj_alarm_level["st_" + row[name]] + "</span>";
        //     }
        //     return pos;
        //   },
        // },
        {
          field: "bj_nr",
          label: this.$t("map.zxbjnr_name"),
          width: "100px",
          type: "fun",
          render: (row) => {
            // let bj = row["lAlarmMsg"];
            let bj = row["bj_nr"];
            let ay = [];
            if (bj != "") {
              let bj_ay = bj.split(",");
              bj_ay.forEach((it) => {
                if (
                  typeof this.$t("map.e_" + it) != "undefined" &&
                  this.$t("map.e_" + it)
                )
                  ay.push(this.$t("map.e_" + it));
              });
            }

            return '<span class="tree_red">' + ay.join(";") + "</span>";
          },
        },
        // {
        //   field: "ckmx",
        //   label: this.$t("map.ckmx"),
        //   width: "100px",
        //   type: "fun",
        //   render: (row) => {
        //     let pos = "";
        //     if (row["alarmguid"] == "") {
        //       pos = "";
        //     } else {
        //       pos =
        //         '<span id="ckmx" data-value="' +
        //         row["alarmguid"] +
        //         '"><i class="fa fa-eye" style="color: #00a0e9"  aria-hidden="true"></i></span>';
        //     }
        //     return pos;
        //   },
        // },
        { field: "lBaiDuPos", label: this.$t("map.bjwz_name") },
        // { field: "lSpeed", label: "速度" },
        {
          field: "drive_name",
          label: this.$t("map.sjxm_name"),
          width: "100px",
        },
        { field: "drive_tel", label: this.$t("map.sjdh_name"), width: "100px" },
        {
          field: "num",
          label: this.$t("map.num"),
          width: "80px",
        },
      ],
      alarmDrawer: false,
      site_title: process.env.VUE_APP_SITE_TITLE,
      userInfo: {
        id: "",
        username: "",
        avatar: "",
      },
    };
  },
  // computed: {
  //   dataAlram() {
  //     return this.alarmData;
  //   },
  // },
  mounted() {
    GpsSocket.addListener((data) => {
      switch (data.type) {
        case "realtime":
          this.realtime_proc(data);
          break;
      }
    });

    this.$api.get_config_imgs({}).then((res) => {
      this.logo = this.$imgUrl + "/" + res.data.logo;
    });
  },
  computed: {},
  watch: {},
  methods: {
    onSubmit() {
      if (this.edit_form.oPwd == "") {
        this.$alert(this.$t("login.oPwd_empty"));
        return;
      }
      if (this.edit_form.uPwd == "") {
        this.$alert(this.$t("login.uPwd_empty"));
        return;
      }
      if (this.edit_form.cPwd == "") {
        this.$alert(this.$t("login.cPwd_empty"));
        return;
      } else {
        if (this.edit_form.uPwd != this.edit_form.cPwd) {
          this.$alert(this.$t("login.pwdNOSame"));
          return;
        }
      }
      this.$api.sys_users_update_pwd(this.edit_form).then((res) => {
        if (res.data.result == 1) {
          this.$alert(this.$t("login.msgType_" + res.data.msg_type));
        } else {
          this.$alert(this.$t("login.msgType_" + res.data.msg_type));
        }
      });
    },
    editOpened() {},
    realtime_proc(data) {
      let ay = [];
      switch (data.content.order) {
        case "alarm":
          //实时监控页面生成报警信息
          // console.log(JSON.stringify(data));
          if (data.content.content && data.content.content.length > 0) {
            // ay = this.dataAlram.concat();
            data.content.content.forEach((it) => {
              let is_add = 0;
              let ay = it.bj_nr.split(",");
              ay.forEach((ik) => {
                this.ay_alarm.forEach((im) => {
                  if (parseInt(ik) == im) {
                    is_add = 1;
                  }
                });
              });
              if (is_add == 1) {
                let key = it.cph + "$" + it.bj_nr;
                if (!this.dataList[key]) {
                  this.dataList[key] = { row: {}, num: 0 };
                }
                // ay.in(it);
                // ay.unshift(it);
                //获得位置信息
                this.$api
                  .bdmap_get_gps_addr({
                    lng: parseFloat(it.lng),
                    lat: parseFloat(it.lat),
                  })
                  .then((res_addr) => {
                    it.lBaiDuPos = res_addr.data.formatted_address;
                    // this.alarmData.unshift(it);
                    this.dataList[key].row = it;
                    this.dataList[key].num = this.dataList[key].num + 1;
                    this.first_data();
                    // this.parse_data();
                  });
                // it.lBaiDuPos = it.lng + "," + it.lat;
                // this.dataList[key].row = it;
                // this.dataList[key].num = this.dataList[key].num + 1;
                // this.first_data();
              }
            });

            // console.log(this.alarmData);
            // this.dataAlram = ay;
          }
          break;
      }
    },
    first_data() {
      let row;
      let ay = [];
      for (let p in this.dataList) {
        row = this.dataList[p].row;
        row.num = this.dataList[p].num;
        ay.push(row);
      }
      this.alarmData = ay;
      this.alarmData.sort((a, b) => {
        return b.gps_time - a.gps_time;
      });

      // console.log(this.alarmData);
      this.parse_data();
    },
    view_click(row) {
      this.rowAlarm = row;
      this.show_alarm_detail = true;
    },
    eventAlarmDetail(obj) {
      switch (obj.type) {
        case "close":
          this.show_alarm_detail = false;
          break;
      }
    },
    onRowDblclick(row) {
      console.log("wxw", row);
      this.rowAlarm = row;
      this.show_alarm_detail = true;
    },
    getAlarmList() {
      // if (this.sel_val == "") return;
      let query = {
        time_type: this.time_act,
        alarm_id: this.sel_val,
      };
      this.$api.alarm_get_alarm_list_by_id(query).then((res) => {
        // console.log(res.data);
        this.dataAlram = res.data.list;
      });
    },
    timeClick(id) {
      this.time_act = id;
      this.getAlarmList();
    },
    search_data(it) {
      this.sel_val = it.value;
      // console.log("search_data", it.value);
      this.parse_data();
      // this.getAlarmList();
    },

    parse_data() {
      let ay = [];
      let ay_ay = [];

      this.leftNav.forEach((yj) => {
        yj.num = 0;
        yj.children.forEach((ej) => {
          ej.num = 0;
        });
      });

      this.alarmData.forEach((it) => {
        ay_ay = it.bj_nr.split(",").map(Number);
        if (ay_ay.includes(parseInt(this.sel_val))) {
          ay.push(it);
        }
        ay_ay.forEach((kk) => {
          this.leftNav.forEach((yj) => {
            yj.children.forEach((ej) => {
              // console.log("ej", ej);
              if (parseInt(ej.value) == kk) {
                //
                ej.num++;
              }
            });
          });
        });
      });

      this.leftNav.forEach((yj) => {
        let sum = 0;
        yj.children.forEach((ej) => {
          sum += ej.num;
        });
        yj.num = sum;
      });

      this.dataAlram = ay;
      let num = 0;
      this.leftNav.forEach((it) => {
        num += parseInt(it.num);
      });
      this.alarm_num = num;
    },
    eventDownload(obj) {
      switch (obj.type) {
        case "close":
          this.show_download = false;
          break;
      }
    },
    eventAlarm(obj) {
      switch (obj.type) {
        case "close":
          this.show_alarm = false;
          break;
      }
    },
    clickDownload() {
      this.show_download = true;
    },
    clickAlarm() {
      this.show_alarm = true;
    },
    btnAlarm() {
      this.alarmDrawer = true;
    },
    editpwd() {
      this.editClickDialog = true;
    },
    logout() {
      this.$store.commit("clearToken");
      this.$store.commit("changeRouteStatus", false);
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped lang="scss">
.ai-alarm {
  width: 50px;
  height: 55px;
  // border-left: 1px dashed hsla(0, 0%, 100%, 0.2);
  background-image: url(../../assets/alarm.gif);
  background-size: auto 60%;
  background-position: center 16px !important;
  background-repeat: no-repeat;
  // position: absolute;
  // top: 2px;
  // right: 100px;
  color: #232323;
  padding-top: 0 !important;
  .ai-alarm-1 {
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    // padding: 0 8px;
    .alarm-num {
      display: inline-block;
      border: 1px solid #fff;
      background-color: #ef4d4d;
      color: #fff;
      line-height: 14px;
      border-radius: 8px;
      // margin-top: 5px;
      padding: 0 5px;
    }
  }
}
.pr10 {
  padding-right: 20px;
}
.gps_control_font {
  font-size: 23px;
  color: azure;
  line-height: 60px;
  cursor: pointer;
}
::v-deep(.el-drawer__header) {
  margin-bottom: 0;
  padding: 5px 20px;
}
::v-deep(.el-drawer__body) {
  padding: 0px 20px;
}
.alarm {
  .alarm-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 48px;
    .top-it {
      width: 120px;
      text-align: center;
    }
    .time_act {
      color: #fff;
      background: #409eff;
    }
  }
  .alarm-bottom {
    display: flex;
    flex-direction: row;
    .alarm-l {
      width: 420px;
      .alarm-it {
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        // background-color: rgb(185, 213, 234);
        padding: 0 0px;
        font-size: 15px;
        cursor: pointer;
      }
      .alarm-act {
        color: #fff;
        background: #409eff;
      }
    }
    .alarm-r {
      flex: 1;
    }
  }
}
.link-title {
  color: white !important;
  line-height: 55px;
  cursor: pointer;
}
.quit {
  flex: 1;
}
.gps-container {
  height: 100vh;
}
::v-deep(.el-header) {
  padding: 0;
  height: 55px;
  overflow: hidden;
}
::v-deep(.el-main) {
  padding: 0;
}
.gps-header {
  height: 55px;
  background: rgb(44, 120, 191);
  .logo {
    img {
      //background: url(../../assets/menu-logo.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
::v-deep .el-drawer__title {
  border-left: none !important;
}
.alarm-top {
  height: 40px;
  border-bottom: 1px solid #ccc;
  font-size: 15px;
  line-height: 40px !important;
  margin-bottom: 10px;
}
.alarm-l {
  border: 1px solid #eee;
}
::v-deep .el-tabs__item.is-active {
  background: #409eff;
  color: #fff !important;
  border-radius: 5px;
  border: 1px solid #e0e6f1;
}
::v-deep .alarm-l .el-tabs__content {
  background: #f9fbff;
  padding: 10px 15px;
  height: 100%;
}
::v-deep .el-tabs--left .el-tabs__header.is-left {
  margin-right: 0px;
  background: #f0f5ff;
  padding: 10px;
}
.alarm-r {
  margin-left: 10px;
}
::v-deep .el-tabs__active-bar {
  background: none;
}
::v-deep .el-tabs--left .el-tabs__nav-wrap.is-left::after {
  background: none;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  margin-bottom: 15px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
}
.alarm-it {
  border-bottom: 1px solid #eee;
  padding-left: 10px !important;
}
::v-deep .alarm .alarm-bottom .alarm-l .alarm-act {
  color: #409eff;
  background: none;
}

::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
::v-deep .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 20px;
}
::v-deep .el-dialog__header {
  text-align: left;
}
::v-deep #tab-0 {
  color: #eb1b11 !important;
}
::v-deep #pane-0 .alarm-it {
  color: #eb1b11 !important;
}
::v-deep #pane-0 .alarm-act {
  color: #fff !important;
  background: #eb1b11;
  border-radius: 5px;
}
::v-deep #tab-1 {
  color: #fe781e !important;
}
::v-deep #pane-1 .alarm-it {
  color: #fe781e !important;
}
::v-deep #pane-1 .alarm-act {
  color: #fff !important;
  background: #fe781e;
  border-radius: 5px;
}
::v-deep #tab-2 {
  color: #d7b02a !important;
}
::v-deep #pane-2 .alarm-it {
  color: #d7b02a !important;
}
::v-deep #pane-2 .alarm-act {
  color: #fff !important;
  background: #d7b02a;
  border-radius: 5px;
}
::v-deep #tab-3 {
  color: #32a6cc !important;
}
::v-deep #pane-3 .alarm-it {
  color: #32a6cc !important;
}
::v-deep #pane-3 .alarm-act {
  color: #fff !important;
  background: #32a6cc;
  border-radius: 5px;
}
::v-deep #tab-0.is-active {
  background: #eb1b11 !important;
  color: #fff !important;
}
::v-deep #tab-1.is-active {
  background: #fe781e !important;
  color: #fff !important;
}
::v-deep #tab-2.is-active {
  background: #d7b02a !important;
  color: #fff !important;
}
::v-deep #tab-3.is-active {
  background: #32a6cc !important;
  color: #fff !important;
}
::v-deep .el-table__body,
::v-deep .el-table {
  color: #000 !important;
}
::v-deep .el-table tr {
  color: #000;
}
::v-deep .alarm-l .el-tabs--left {
  width: 418px;
}
::v-deep .alarm-l .el-tabs__content {
  overflow-y: auto;
}
/* 针对.el-transfer-panel__list的滚动条进行美化 */
::v-deep .alarm-l .el-tabs--left ::-webkit-scrollbar {
  width: 5px; /* 滚动条宽度 */
  height: 5px; /* 滚动条高度（通常不需要设置，针对垂直滚动条） */
}

/* 滚动条轨道样式 */
::v-deep .alarm-l .el-tabs--left ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.03); /* 轨道背景色 */
  border-radius: 10px; /* 轨道圆角 */
}

/* 滚动条滑块样式 */
::v-deep .alarm-l .el-tabs--left ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.03); /* 轨道背景色 */
  border-radius: 10px; /* 滑块圆角 */
}

/* 可选：悬停时的滑块样式 */
::v-deep .alarm-l .el-tabs--left ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2); /* 悬停时颜色变化 */
}
.da3 {
  color: rgb(44, 120, 191);
  font-size: 20px;
}
.bbr {
  border: none;
}
.alarm .alarm-bottom .alarm-l .alarm-it {
  margin: 5px 0px;
  height: 30px;
  line-height: 30px;
}
.menu_items {
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  padding-right: 10px;
}
.alarm-r ::v-deep .el-table__inner-wrapper {
  height: calc(100vh - 100px);
}
#mima ::v-deep .el-form-item--small .el-form-item__label {
  width: 80px;
  text-align: right;
}
#mima .el-button--small {
  margin: 0 auto;
  padding: 7px 20px;
}
</style>
