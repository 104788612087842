<template>
  <el-container
    class="gps-body"
    style="background: #f2f2f2; padding-right: 10px"
  >
    <el-aside class="gps-reprot-left">
      <el-menu
        active-text-color="#ffd04b"
        background-color="#fff"
        class="el-menu-vertical-demo"
        :default-active="default_active_id"
        text-color="#000"
        @open="handleOpen"
        @close="handleClose"
        style="heigth: 100%"
      >
        <el-menu-item
          :index="it.id + ''"
          v-for="it in menus"
          :key="it.id"
          @click="go_url(it)"
        >
          <el-icon><location v-if="false" /></el-icon>
          <span>{{ it.name }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main height="" style="overflow: hidden">
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from "@element-plus/icons-vue";
import {
  reactive,
  ref,
  onMounted,
  provide,
  getCurrentInstance,
  watch,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import api from "../../api";
export default {
  components: { Location },
  setup(props, content) {
    let { proxy } = getCurrentInstance();
    const default_active_id = ref("7");
    const router = useRouter();
    const go_url = (options) => {
      if (options.rName) {
        router.push({ name: options.rName });
      }
    };
    const handleOpen = (key, keyPath) => {
      default_active_id.value = key.toString();
      console.log(key, keyPath);
    };
    const handleClose = (key, keyPath) => {
      console.log(key, keyPath);
    };
    const menus = ref([
      // { id: 1, name: "里程报表", icon: "", rName: "reports-lccx" },
      // { id: 2, name: "上下线报表", icon: "", rName: "reports-sxxtj" },
      // { id: 3, name: "Acc开关报表", icon: "", rName: "reports-accdhtj" },
      // { id: 4, name: "常规报警", icon: "", rName: "reports-cgbj" },
      // { id: 5, name: "故障报警", icon: "", rName: "reports-gzbj" },
      // { id: 6, name: "主动安全报警", icon: "", rName: "reports-zdaqbj" },
      // { id: 7, name: "电子围栏", icon: "", rName: "reports-dzwl" },
      // { id: 8, name: "数据流量", icon: "", rName: "reports-sjll" },
      // { id: 9, name: "用户日志", icon: "", rName: "reports-yhxw" },
      // { id: 10, name: "车辆驾驶评分", icon: "", rName: "reports-jspf" },
    ]);

    proxy.$api.menu_get_menu_list_by_role({ pid: 4 }).then((res) => {
      // console.log(res.data);
      let ay = [];
      res.data.list.forEach((it) => {
        ay.push({
          id: it.id,
          name: it.mTitle,
          icon: it.mIcon,
          rName: it.mRName,
        });
      });
      menus.value = ay;
    });

    ///////////////////
    const propoption = {
      icon: "icon",
      value: "gps_id",
      label: "nodeName",
      children: "children",
    };

    // const treeData = ref([]);

    const treeData = reactive({ data: [] });

    const getList = () => {
      api.tree_deptTree_Tree({}).then((res) => {
        treeData.data = res.data.resultData;
      });
    };

    onMounted(() => {
      console.log("report");
      // getList();
    });

    const treeRef = ref();
    // const query_key = ref("");
    // const onQueryChanged = (query) => {
    //   treeRef.value.filter(query);
    // };
    // const filterMethod = (query, node) => {
    //   return node.nodeName.includes(query);
    // };

    //provide/inject
    const PARENT_PROVIDE = "REPORT_EVENT";
    const callbackFun = (obj) => {
      console.log("wxw", obj);
    };
    provide(PARENT_PROVIDE, callbackFun);
    provide("treeRef", treeRef);

    watch(
      () => router.currentRoute.value.path,
      (newValue, oldValue) => {
        // console.log("watch", newValue);
        if (newValue.indexOf("/lccx") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "7";
          });
        }
        if (newValue.indexOf("/sxxtj") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "8";
          });
        }
        if (newValue.indexOf("/accdhtj") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "9";
          });
        }
        if (newValue.indexOf("/cgbj") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "10";
          });
        }
        if (newValue.indexOf("/gzbj") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "11";
          });
        }
        if (newValue.indexOf("/sjll") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "14";
          });
        }
        if (newValue.indexOf("/yhxw") > -1) {
          default_active_id.value = "0";
          nextTick(() => {
            default_active_id.value = "15";
          });
        }
        //
      },
      { immediate: true }
    );

    return {
      // filterMethod,
      treeRef,
      handleOpen,
      handleClose,
      menus,
      propoption,
      treeData,
      // query_key,
      // onQueryChanged,
      go_url,
      default_active_id,
    };
  },
};
</script>

<style lang="scss" scoped>
.gps-body {
  height: 100%;
  .gps-reprot-left {
    width: 200px;
    background: #fff;
    //border:1px solid #ccc;
    margin-right: 10px; //border:1px solid #e5e2e2;
    border-top: 1px solid #e5e2e2;
    border-right: 1px solid #e5e2e2;
  }
  .left {
    box-sizing: border-box;
    padding: 10px 0 0 10px;
  }
}
::v-deep(.el-menu) {
  border-right: none;
}

::v-deep .gps-car {
  //border-top:1px solid #ccc;
}
::v-deep .gps-box {
  padding: 0px;
}
::v-deep .gps-search {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}
::v-deep .el-tabs {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
::v-deep .el-button--success {
  background: #2485e7 !important;
  border: none;
}

::v-deep .is-round {
  background: #03b356;
  border: none;
  color: #fff;
  height: 30px !important;
}

::v-deep .el-button--warning {
  background: #ff960c !important;
}
::v-deep .is-round:hover {
  opacity: 0.7;
}
::v-deep .is-round:nth-child(2):hover {
  background: #2fc978 !important;
  border: none;
  color: #fff;
  height: 30px !important;
}
::v-deep .el-select .el-input__inner {
  height: 30px !important;
}
::v-deep .el-range-editor--small.el-input__wrapper {
  height: 32px !important;
}
::v-deep .el-table--fit {
  border: 1px solid #e0dede;
}
::v-deep .el-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  height: 45px;
  line-height: 45px;
}
::v-deep .el-menu li:nth-child(1) {
  background: url(../../assets/meun/c1.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(2) {
  background: url(../../assets/meun/c2.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(3) {
  background: url(../../assets/meun/c3.png) no-repeat 20px center;
}

::v-deep .el-menu li:nth-child(4) {
  background: url(../../assets/meun/c4.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(5) {
  background: url(../../assets/meun/c5.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(6) {
  background: url(../../assets/meun/c6.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(7) {
  background: url(../../assets/meun/c7.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(8) {
  background: url(../../assets/meun/c8.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(9) {
  background: url(../../assets/meun/c9.png) no-repeat 20px center;
}
::v-deep .el-menu li:nth-child(10) {
  background: url(../../assets/meun/c10.png) no-repeat 20px center;
}
::v-deep .el-menu-item.is-active {
  color: #428ddf;
}
::v-deep .el-menu {
  padding-top: 5px;
}
::v-deep .el-table tr:nth-child(even) {
  background: #f9f9f9;
}
::v-deep .el-table tr th {
  height: 30px;
}
::v-deep .el-table__body-wrapper tr td.el-table-fixed-column--left {
  background: none;
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #e1e1e1;
}
::v-deep .el-table_body tr.hover-row > td.el-table_cell {
  background: #f9f9f9;
}
::v-deep .el-table__body-wrapper tr:hover > td.el-table-fixed-column--left {
  background: #f5f7fa;
}
::v-deep .demo-tabs > .el-tabs__content {
  padding: 0px;
}
::v-deep .aa {
  background: #fff;
  //border:1px solid #ccc
}
::v-deep .el-table--fit {
  //border:1px solid #ccc;
}
::v-deep .gps-search {
  padding: 8px 10px 0px 10px;
  border-radius: 0px;
  height: 50px;
  background: #fff;
  //border-bottom:1px solid #ccc;
}
::v-deep .el-tabs {
  border: none;
}
::v-deep .el-input--small .el-input__wrapper {
  padding: 1px 7px;
  height: 34px;
}
</style>
